import cx from 'classnames';
import { type ReactNode, useEffect, useRef, useState } from 'react';

interface CardSectionProps {
  title?: string;
  children: ReactNode;
  className?: string;
  notice?: ReactNode;
}

export default function CardSection (
  { children, notice, title, className }: CardSectionProps,
): JSX.Element {
  const elementId = `${title.toLowerCase()}-section`;

  const el = useRef(null);
  const [isShowing, setIsShowing] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1);
      },
      { threshold: [1] },
    );

    observer.observe(el.current);

    return () => observer.disconnect();
  }, []);

  return (
    <section
      className={cx(
        'transition-[grid-template-rows] duration-300 grid bg-white shadow-md mb-4',
        className,
        {
          'grid-rows-[min-content_1fr]': isShowing,
          'grid-rows-[min-content_0fr] !pb-0': !isShowing,
        },
      )}
    >
      <div
        ref={el}
        role='button'
        tabIndex={0}
        className='flex sticky py-8 font-medium bg-white px-[24px] top-[-2px] font-effra text-[16px] data-[stuck]:z-[11]'
        onClick={() => {
          setIsShowing(!isShowing);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsShowing(!isShowing);
          }
        }}
      >
        {title}
        {notice}
      </div>
      <div
        id={elementId}
        data-testid={elementId}
        className={cx('flex flex-col transition-opacity duration-300 gap-[4px]', {
          'opacity-0 overflow-hidden': !isShowing,
          'opacity-100': isShowing,
        })}
      >
        {children}
      </div>
    </section>
  );
}
