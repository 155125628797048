/* eslint-disable @typescript-eslint/indent */
import { ConfirmModal, MobileUnsupported, SideNavigation, TermsOfUseModal } from '@/components';
import BannerContainer from '@/components/banners/BannerContainer';
import { CreateNewObjectCard } from '@/components/common/CreateNewObjectCard/CreateNewObjectCard';
import ImpersonationContextProvider from '@/components/common/ImpersonationContext';
import InternalObjectCard from '@/components/common/ModularObject/InternalObjectCard';
import ZendeskChatWidget from '@/components/modules/ZendeskChatWidget';
import ResizeableDrawer from '@/components/ResizeableDrawer/ResizeableDrawer';
import { addToastError } from '@/components/Toast/utils';
import UserProfileDrawer from '@/components/User/UserProfile/UserProfileDrawer';
import { useRefreshTokenQuery } from '@/graphql/auth/refreshToken.generated';
import {
  GetTemplatesUsedByModularObjectsDocument,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useUpdateUserTosAcknowledgementMutation } from '@/graphql/user/userMutations.generated';
import { useLogout } from '@/hooks/useLogout';
import { selectRootState } from '@/state/selectors';
import { login, setUser } from '@/state/slices/session.slice';
import { PUBLIC_ROUTES } from '@/util/constants';
import { env } from '@/util/env.utils';
import { useGetDependencySubscription, useGetModularObjectSubscription } from '@/util/getSubscriptions.generated';
import { loadPluralizeExceptions } from '@/util/loadPluralizeExceptions';
import { getHydratedModularObjectsAndTemplates } from '@/util/lookup.functions';
import metrics from '@/util/metrics';
import { usePrismatic } from '@/util/prismatic';
import { getHardwareIntegrationsApprovals, getHardwareIntegrationsByType } from '@/util/requests.functions';
import * as amplitude from '@amplitude/analytics-browser';
import { datadogLogs } from '@datadog/browser-logs';
import cx from 'classnames';
import { init } from 'commandbar';
import { hasCookie } from 'cookies-next';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { useGetLoggedInUserQuery } from './getLoggedInUser.generated';
import { useAppDispatch, useAppSelector } from './hooks';
import { useObjectCardContext } from './ObjectCard.context';
import { addApprovals } from './slices/approvals.slice';
import { addIntegrations } from './slices/integration.slice';
import { addModularObjects } from './slices/modularObjects.slice';
import { setLastVisited } from './slices/navigation.slice';
import { addTemplates } from './slices/templates.slice';

loadPluralizeExceptions();

const nonSideNavRoutes = [
  '/login',
  '/login-amazon',
  '/sso-failed',
  '/external/[type]/[id]',
  '/404',
  '/reset-password/[id]',
  '/password-reset-success',
  '/unsubscribe',
  '/hang-tight',
  '/signup',
  '/signup/verify-account',
  '/signup/error',
  '/public-object/[linkId]',
  '/welcome-back/[token]',
  '/object',
];

const mobileOkRoutes = [
  '/unsubscribe',
  '/signup',
  '/signup/verify-account',
];

export function AppWrapper (props: { children: ReactNode }): JSX.Element {
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();
  // new pattern of accessing state.
  const {
    session: sessionState,
  } = useAppSelector(selectRootState);
  const router = useRouter();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(600);
  const { isObjectCardDrawerOpen, newObjectConfig, activeModularObjectId } = useObjectCardContext();
  const [updateTOS] = useUpdateUserTosAcknowledgementMutation();
  const { logout } = useLogout();
  useRefreshTokenQuery({
    skip: !hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME')),
    pollInterval: 15 * 60 * 1000,
    fetchPolicy: 'network-only',
  });
  const { data: userData, loading: isLoadingUser } = useGetLoggedInUserQuery({
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      // this is a hack to get the user into the store.  We eventually do not want to be using redux
      dispatch(setUser(data?.getUser));
    },
  });

  const user = userData?.getUser?.user;

  usePrismatic();
  // Utility function to extract ID
  type CacheReference = { __ref: string } | { id: string };
  const getChildId = (child: CacheReference): string | undefined => {
    if ('__ref' in child) {
      const match = child.__ref.match(/ModularObject:\{"id":"([^"]+)"\}/);
      return match ? match[1] : undefined;
    }
    return child.id;
  };
  useGetModularObjectSubscription({
    onData: ({ client, data }) => {
      client.cache.modify({
        id: client.cache.identify(data?.data?.modularObject),
        fields: {
          subscriptions() {
            return data?.data?.modularObject;
          },
        },
      });

      const obj = data?.data?.modularObject;
      if (!obj) return;

      if (obj.parent) {
        client.cache.modify({
          id: client.cache.identify(obj.parent),
          fields: {
            children(existingChildren = []) {
              const index = existingChildren.findIndex(child => {
                const childId = getChildId(child);
                if (!childId) {
                  console.warn('Unable to extract ID from child:', child);
                  return false;
                }
                return childId === obj.id;
              });

              if (index > -1) {
                existingChildren[index] = obj;
              } else {
                existingChildren.push(obj);
              }

              return existingChildren;
            },
          },
        });
      } else if (!['task', 'driver'].includes(data?.data?.modularObject?.template?.type)) {
        client.cache.modify({
          fields: {
            getTopLevelModularObjects (existing) {
              // TODO: Updated this to use the new edges structure, but do we need to check if the edges are already in the array?
              const existingEdges = existing?.edges ?? [];
              const newEdges = [data?.data?.modularObject, ...existingEdges];
              return {
                ...existing,
                edges: newEdges,
              };
            },
          },
        });
      }

      data?.data?.modularObject?.children?.forEach((child) => {
        client.cache.modify({
          id: client.cache.identify(child),
          fields: {
            parent() {
              return data?.data?.modularObject;
            },
          },
        });
      });

      void client.refetchQueries({ include: [GetTemplatesUsedByModularObjectsDocument] });
    },
  });

  useGetDependencySubscription({
    onData: ({ client, data }) => {
      client.cache.modify({
        id: client.cache.identify(data?.data?.dependency),
        fields: {
          subscriptions() {
            return data?.data?.dependency;
          },
        },
      });
    },
  });

  const isUserOnPreAuthRoute = useMemo(
    () => PUBLIC_ROUTES.some((publicRoute) => router.pathname.includes(publicRoute)),
    [router.pathname],
  );

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const resizeScreen = (): void => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeScreen);

    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  }, []);

  useEffect(() => {
    metrics.init();

    if (user?.id) {
      // We don't want to initialize CommandBar in test environments
      if (process.env.NEXT_PUBLIC_COMMANDBAR_ORGANIZATION_ID && !process.env.TEST_ENV) {
        init(process.env.NEXT_PUBLIC_COMMANDBAR_ORGANIZATION_ID);
      }
      if (window?.CommandBar && user?.id) {
        window.CommandBar.boot(user?.id, {
          email: user?.email,
          role: user?.role,
          hasWorkCapacity: Boolean(user?.workCapacity),
        }).catch(console.error);
      }

      metrics.setUserId(user?.id);
      const identify = new amplitude.Identify().set('email', user?.email).set(
        'organization',
        user?.organization?.name,
      );
      metrics.identify(identify);
    }

    return () => {
      if (window?.CommandBar) {
        window.CommandBar.shutdown();
      }
    };
  }, [user?.id, user?.email, user?.organization?.name, user?.role, user?.workCapacity]);

  useEffect(() => {
    const _getHydratedModularObjectsAndTemplates = async () => {
      const [templates, modularObjects] = await getHydratedModularObjectsAndTemplates();
      dispatch(addTemplates(templates));
      dispatch(addModularObjects(modularObjects));
    };

    if (user) {
      getHardwareIntegrationsApprovals('modular_object')
        .then((approvals) => {
          dispatch(addApprovals({ type: 'integration', approvals }));
        }).catch(console.error);
      getHardwareIntegrationsByType('modular_object')
        .then((integrations) => {
          dispatch(addIntegrations(integrations));
        })
        .catch(console.error);

      void _getHydratedModularObjectsAndTemplates();
    }
  }, [user, dispatch]);

  useEffect(() => {
    // Wait for user and ldClient to initialize
    if (user && ldClient) {
      const userContext = {
        kind: 'user',
        key: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      };
      void ldClient.identify(userContext);
    }
  }, [user, ldClient]);

  useEffect(() => {
    void (async () => {
      if (
        !hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME')) &&
        !PUBLIC_ROUTES.includes(router.pathname) &&
        !sessionState.isLoggedOut
      ) {
        dispatch(setLastVisited(router.pathname));
        datadogLogs.logger.info('No cookie found, User is being logged out');
        metrics.track('session timeout');
        addToastError('Your session has ended. You are being redirected to login.');
        await logout();
      } else if (sessionState.isLoggedOut && hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))) {
        dispatch(login());
      }
    })();
  }, [dispatch, sessionState.isLoggedOut, logout, router.pathname, hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))]);

  useEffect(() => {
    setIsMobile(screenWidth < 600);
  }, [screenWidth]);

  const tosSubmitHandler = useCallback(async (): Promise<void> => {
    try {
      const updatedUser = await updateTOS();
      dispatch(setUser(updatedUser));
      window.location.reload(); // Hack to force users into app router after accepting TOS
    } catch (err) {
      console.error(err);
      addToastError('Something went wrong, please try again later.');
    }
  }, [user, dispatch]);
  const shouldShowMobileUnsupported = isMobile && !mobileOkRoutes.includes(router.pathname);

  const isSidenavRoute = !nonSideNavRoutes.includes(router.pathname);
  const isObjectPage = router.pathname === '/object';
  const shouldShowObjectCard = isObjectCardDrawerOpen && (isSidenavRoute || isObjectPage);
  const isCreatingNewObject = isObjectCardDrawerOpen && Boolean(newObjectConfig?.templateId) && !activeModularObjectId;

  if (isLoadingUser) return <></>;

  return (
    <>
      <ZendeskChatWidget showChat={!isUserOnPreAuthRoute} />
      {shouldShowMobileUnsupported && <MobileUnsupported />}
      <ImpersonationContextProvider>
        <BannerContainer />
        <div className='flex overflow-hidden w-screen h-screen'>
          {isSidenavRoute && <SideNavigation />}
          <div
            className={cx('shrink @container overflow-auto no-scrollbar', {
              'grow': !isObjectPage,
            })}
          >
            {props.children}
          </div>

          <ResizeableDrawer open={shouldShowObjectCard}>
            {isCreatingNewObject ? <CreateNewObjectCard /> : <InternalObjectCard />}
          </ResizeableDrawer>

          <UserProfileDrawer />
        </div>
        <ConfirmModal />
        <TermsOfUseModal
          isModalOpen={(user && !user?.acknowledgements?.termsOfUse && !PUBLIC_ROUTES.includes(router.pathname)) ||
            false}
          onSubmit={tosSubmitHandler}
        />
        <ToastContainer
          data-headlessui-portal
          transition={Slide}
          closeButton={false}
          pauseOnFocusLoss={false}
          className='w-[370px]'
        />
      </ImpersonationContextProvider>
    </>
  );
}
