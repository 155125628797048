import classnames from 'classnames';
import { useRouter } from 'next/router';
import { forwardRef, type ReactNode, type Ref, useEffect, useState } from 'react';

interface ButtonProps {
  id?: string;
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  doesRipple?: boolean;
  doesSpin?: boolean;
  href?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (event) => any | Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event) => any | Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHover?: (event) => any | Promise<void>;
  disabled?: boolean;
  title?: string;
  dataTestId?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  type?: HTMLButtonElement['type'];
}

export const Button = forwardRef(function Button ({
  id,
  children,
  className,
  innerClassName,
  doesRipple,
  doesSpin,
  href,
  onBlur,
  onClick,
  onHover,
  disabled,
  title,
  dataTestId,
  tabIndex,
  autoFocus,
  type,
}: ButtonProps, ref: Ref<HTMLButtonElement>): JSX.Element {
  const router = useRouter();
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState<boolean>(false);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const hrefOnClick = async (): Promise<void> => {
    router.push(href);
  };
  if (href) {
    onClick = hrefOnClick;
  }

  useEffect(() => {
    let timeout = null;

    if (coords.x !== -1 && coords.y !== -1) {
      if (doesRipple) {
        setIsRippling(true);
      }
      if (doesSpin) {
        setIsSpinning(true);
      }
      timeout = setTimeout(() => {
        setIsRippling(false);
      }, 1500);
    } else {
      setIsRippling(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  return (
    <button
      id={id}
      type={type || onClick ? 'button' : 'submit'}
      disabled={isSpinning || disabled}
      onClick={(event) => {
        const { left, top } = event.currentTarget.getBoundingClientRect();
        setCoords({ x: event.clientX - left, y: event.clientY - top });
        setTimeout(() => {
          setIsSpinning(false);
        }, 200);
        onClick?.call(this, event);
      }}
      onMouseOver={onHover}
      onMouseOut={onBlur}
      onFocus={onHover}
      onBlur={onBlur}
      className={classnames('relative overflow-hidden', className, 'rounded-[2px]')}
      title={title}
      data-testid={dataTestId}
      ref={ref}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
    >
      {isRippling && (
        <span
          className='absolute w-20 h-20 bg-white rounded-full animate-ripple'
          style={{ left: coords.x, top: coords.y }}
        />
      )}
      <span className='relative truncate'>
        {isSpinning && (
          <svg
            className='absolute -left-7 w-5 h-5 text-white animate-spin'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        )}
        <span className={classnames(innerClassName, 'flex items-center justify-center')}>{children}</span>
      </span>
    </button>
  );
});
