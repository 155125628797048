import type { ShareInput, ShareRole, SharesUserInput, Team as TeamType, TeamShare, User } from '@/__generated__/types';
import cx from 'classnames';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { useCollabDiscoveryContext } from './CollabDiscovery.context';
import CollaboratorRoleDropdown, { StaticRole } from './CollaboratorRoleDropDown';
import { DropdownOption } from './hooks/useDropdownOptionMap';
import { useDeleteCollaboratorMutation } from './mutations/deleteShares.generated';
import { useUpdateCollaboratorsMutation } from './mutations/updateShares.generated';

import { GetModularObjectByIdDocument } from '@/components/modals/ObjectSelectionModal/getModularObjectById.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import Collaborator from '@/designSystemComponents/Collaborator';
import Team from '@/designSystemComponents/Team';
import { useScrollWithShadow } from '@/hooks/useScrollWithShadow';
import { useAppDispatch } from '@/state/hooks';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-regular-svg-icons';
import { faUser } from '@fortawesome/sharp-solid-svg-icons';

import { useMemo, useState } from 'react';
import { useAddNewCollaboratorsMutation } from './mutations/addShares.generated';
import { useDeleteTeamSharesMutation } from './mutations/deleteTeamShares.generated';
import { useUpdateModularObjectMutation } from './mutations/updateModularObject.generated';
import PendingInvite from './PendingInvite';
import { GetCollaboratorListDocument } from './queries/getCollaboratorList.generated';

interface CollaboratorItemProps {
  user: User;
  role: DropdownOption;
  handleRoleChange: (shareId: string, user: User) => (roleValue: DropdownOption) => void;
  isAnonymousUser: boolean;
  currentUserId: string;
  currentUserRole: string;
  id: string;
  teams: TeamType[];
  collaborators: Array<
    {
      __typename?: 'Share';
      id: string;
      role: string;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        profileImage: string;
        organizationId: string;
      };
    } | { __typename?: 'AnonymousUser'; id: string; email: string }
  >;
  roleOptionMap: Map<string, DropdownOption[]>;
}

function CollaboratorItem (props: CollaboratorItemProps) {
  const {
    user,
    role,
    handleRoleChange,
    isAnonymousUser,
    currentUserId,
    currentUserRole,
    id,
    collaborators,
    roleOptionMap,
    teams,
  } = props;

  if (isAnonymousUser) return null;

  return (
    <>
      <Collaborator user={user} teams={teams} />
      <CollaboratorRoleDropdown
        autoRotate={collaborators.length > 4}
        isNotEditor={(user?.id !== currentUserId && currentUserRole === DropdownOption.Viewer) || !currentUserRole}
        value={role}
        options={roleOptionMap.get(currentUserRole)}
        onChange={handleRoleChange(id, user)}
        disableRemove={teams?.length > 0}
      />
    </>
  );
}

interface ConfirmCollaboratorRemovalProps {
  shareUpdate: {
    id: string;
    role: ShareRole;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserToRemove: (value: any) => void;
  currentUserId: string;
}

function ConfirmCollaboratorRemoval ({
  shareUpdate,
  setUserToRemove,
  currentUserId,
}: ConfirmCollaboratorRemovalProps) {
  const dispatch = useAppDispatch();
  const [removeCollaborators] = useDeleteCollaboratorMutation();

  const isCurrentUserBeingRemoved = shareUpdate.userId === currentUserId;

  const handleConfirm = async () => {
    try {
      setUserToRemove(null);
      await removeCollaborators({
        variables: {
          input: {
            shares: [shareUpdate],
            allowPartialSuccess: false,
          },
          teamShareIds: [],
          allowPartialSuccess: false,
        },
        onQueryUpdated: (observableQuery) => {
          void observableQuery.refetch();
          if (isCurrentUserBeingRemoved) dispatch(closeModularObjectModal());
        },
        refetchQueries: isCurrentUserBeingRemoved
          ? [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument]
          : [],
      });
      addToastSuccess('Collaborator removed');
    } catch (error) {
      console.error(error);
      addToastError('Failed to remove collaborator');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to remove this collaborator?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setUserToRemove(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

type ExtendedUser = User & {
  isExternal?: boolean;
  isAnonymousUser?: boolean;
  firstName: string;
  lastName: string;
};

interface ConfirmOwnerShipTransferProps {
  shareUpdate: {
    id: string;
    role: DropdownOption;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOwnerToTransfer: (value: any) => void;
  objectOwner: ExtendedUser;
}

function ConfirmOwnerShipTransfer ({ shareUpdate, setOwnerToTransfer, objectOwner }: ConfirmOwnerShipTransferProps) {
  const [addNewCollaborators] = useAddNewCollaboratorsMutation();
  const [updateModularObject] = useUpdateModularObjectMutation({
    onQueryUpdated: (observableQuery) => {
      void observableQuery.refetch();
    },
  });

  const handleConfirm = async () => {
    try {
      setOwnerToTransfer(null);
      await addNewCollaborators({
        variables: {
          input: {
            shares: [{
              ...shareUpdate,
              id: uuidv5(`ownershipTransfer-${objectOwner?.id}`, uuidv4()),
              role: DropdownOption.Editor,
              user: {
                id: objectOwner?.id,
                firstName: objectOwner?.firstName,
                lastName: objectOwner?.lastName,
                email: objectOwner?.email,
                organizationId: objectOwner?.organizationId,
                profileImage: objectOwner?.profileImage,
              } as SharesUserInput,
              userId: objectOwner?.id,
            }],
            allowPartialSuccess: false,
          },
          teamShares: null,
          externalLinkInput: [],
        },
      });
      await updateModularObject({
        variables: {
          input: {
            diffs: [{
              diffType: 'updated',
              externalID: shareUpdate.externalId,
              externalType: 'modular_object',
              diff: {
                displayNames: { ownerId: 'ownerId' },
                to: { ownerId: shareUpdate.userId },
              },
              fromMigration: false,
            }],
          },
        },
      });
      addToastSuccess('Ownership transferred');
    } catch (error) {
      console.error(error);
      addToastError('Failed to transfer ownership');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to transfer ownership of this object?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setOwnerToTransfer(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface ConfirmExternalCollaboratorsProps {
  showExternalUserConfirmation: boolean;
  showExternalTeamConfirmation: boolean;
  setExternalUserAddition: (value: boolean) => void;
  setExternalTeamAddition: (value: boolean) => void;
  submitExternalCollaborators: () => void;
  externalUserMessage?: string;
}

export function ConfirmExternalCollaborators (
  {
    showExternalUserConfirmation,
    showExternalTeamConfirmation,
    setExternalUserAddition,
    setExternalTeamAddition,
    submitExternalCollaborators,
    externalUserMessage,
  }: ConfirmExternalCollaboratorsProps,
) {
  function handleConfirmSubmission () {
    setExternalUserAddition(false);
    setExternalTeamAddition(false);
    submitExternalCollaborators();
  }

  const message = useMemo(() => {
    if (showExternalUserConfirmation && showExternalTeamConfirmation) {
      return 'You are attempting to share this item with a people from outside your company.';
    }
    if (showExternalTeamConfirmation) {
      return 'You are attempting to share this item with a team that has members from outside your company.';
    }
    return externalUserMessage ?? 'You are attempting to share this item with someone outside your company.';
  }, [showExternalUserConfirmation, showExternalTeamConfirmation]);

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <div className='bg-tertiary' style={{padding: '5px 10px', borderRadius: '5px'}}><FontAwesomeIcon
                    icon={faUser}
                    color='#FFF'
                  /></div>
      
      <p className='flex gap-1 text-center effra-14'>
        <i className='fa-sharp fa-solid fa-triangle-exclamation text-warning' />
        <span>{message}</span>
      </p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setExternalUserAddition(false);
            setExternalTeamAddition(false);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirmSubmission}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface ConfirmTeamRemovalProps {
  teamShareToRemove: TeamShare;
  setTeamShareToRemove: (value: TeamShare) => void;
}

function ConfirmTeamRemoval ({ teamShareToRemove, setTeamShareToRemove }: ConfirmTeamRemovalProps) {
  const [deleteTeamShare] = useDeleteTeamSharesMutation();

  const handleRemoveTeamShare = async () => {
    await deleteTeamShare({
      variables: {
        ids: [teamShareToRemove.id],
      },
      refetchQueries: [GetCollaboratorListDocument],
      onCompleted: () => {
        addToastSuccess('Team removed');
        setTeamShareToRemove(null);
      },
      onError: (error) => {
        console.error(error);
        addToastError('Failed to remove team');
        setTeamShareToRemove(null);
      },
    });
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='text-center effra-14'>
        <span className='font-medium'>Are you sure you want to remove this team?</span>
        <br />
        <span>
          All team members will lose access unless they have been added individually or as part of another team.
        </span>
      </p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{ size: 'small', style: 'outline' }}
          onClick={() => setTeamShareToRemove(null)}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleRemoveTeamShare}>
          Remove Team
        </Button>
      </div>
    </div>
  );
}

interface CollaboratorsListProps {
  objectId: string;
}
export default function CollaboratorsList ({ objectId }: CollaboratorsListProps) {
  const {
    objectOwner,
    currentUserRole,
    currentUserId,
    currentUserIsOwner,
    initialUsers: collaborators,
    initialTeams: teamShares,
    externalUserAddition,
    setExternalUserAddition,
    externalTeamAddition,
    setExternalTeamAddition,
    handleSubmitSharedUsers,
  } = useCollabDiscoveryContext();

  const [updateCollaborator] = useUpdateCollaboratorsMutation();
  const { boxShadow: { showTop, showBottom }, onScrollHandler } = useScrollWithShadow();

  const [userToRemove, setUserToRemove] = useState(null);
  const [teamShareToRemove, setTeamShareToRemove] = useState(null);
  const [ownerToTransfer, setOwnerToTransfer] = useState(null);

  const handleRoleChange = (shareId, user) => {
    return async (roleValue: DropdownOption) => {
      try {
        const shareUpdate = {
          id: shareId,
          role: roleValue,
          externalType: 'modular_object',
          externalId: objectId,
          userId: user?.id,
          user: {
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            organizationId: user?.organizationId,
            profileImage: user?.profileImage,
            fullName: null,
            title: null,
          },
        };

        if (roleValue === DropdownOption.Owner) {
          setOwnerToTransfer(shareUpdate);
        } else if (roleValue === DropdownOption.Remove) {
          setUserToRemove({ ...shareUpdate, role: DropdownOption.Viewer });
        } else {
          await updateCollaborator({
            variables: {
              input: {
                shares: [shareUpdate as ShareInput],
                allowPartialSuccess: false,
              },
            },
          });
          addToastSuccess('Collaborator role updated');
        }
      } catch (error) {
        console.error(error);
        addToastError('Failed to update collaborator role');
      }
    };
  };

  const { Owner, Editor, Viewer, Remove } = DropdownOption;

  const roleOptionMap = new Map();
  roleOptionMap.set('Owner', [Owner, Editor, Viewer, Remove]);
  roleOptionMap.set('Editor', [Editor, Viewer, Remove]);
  roleOptionMap.set('Viewer', [Viewer, Remove]);

  if (externalUserAddition || externalTeamAddition) {
    return (
      <ConfirmExternalCollaborators
        showExternalTeamConfirmation={externalTeamAddition}
        showExternalUserConfirmation={externalUserAddition}
        setExternalUserAddition={setExternalUserAddition}
        setExternalTeamAddition={setExternalTeamAddition}
        submitExternalCollaborators={handleSubmitSharedUsers}
      />
    );
  }

  if (userToRemove) {
    return (
      <ConfirmCollaboratorRemoval
        shareUpdate={userToRemove}
        setUserToRemove={setUserToRemove}
        currentUserId={currentUserId}
      />
    );
  }

  if (ownerToTransfer) {
    return (
      <ConfirmOwnerShipTransfer
        shareUpdate={ownerToTransfer}
        setOwnerToTransfer={setOwnerToTransfer}
        objectOwner={objectOwner as ExtendedUser}
      />
    );
  }

  if (teamShareToRemove) {
    return (
      <ConfirmTeamRemoval
        teamShareToRemove={teamShareToRemove}
        setTeamShareToRemove={setTeamShareToRemove}
      />
    );
  }

  if (objectOwner?.id === currentUserId) {
    (objectOwner as ExtendedUser).firstName = 'You';
    (objectOwner as ExtendedUser).lastName = '';
  }

  return (
    <div className='relative z-5' data-testid='collaborators-list'>
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -top-8 bg-white blur', {
          'hidden': !showTop || (collaborators.length + teamShares.length) < 5,
        })}
      />
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -bottom-8 bg-white blur', {
          'hidden': !showBottom || collaborators.length < 5,
        })}
      />
      <div
        className={cx('flex flex-col gap-[8px] max-h-[230px]', {
          'overflow-y-scroll': (collaborators.length + teamShares.length) > 4,
        })}
        onScroll={onScrollHandler}
      >
        <div className='flex justify-between items-center'>
          {/* @ts-expect-error - type data needs to be fixed */}
          <Collaborator user={objectOwner} />
          <StaticRole role={DropdownOption.Owner} />
        </div>
        {/* @ts-expect-error - type data needs to be fixed */}
        {collaborators.map(({ id, user, role, __typename, email, teams }) => {
          const isAnonymousUser = __typename === 'AnonymousUser';
          if (user?.id === objectOwner?.id) {
            user.firstName = 'You';
            user.lastName = '';
          }
          return (
            <div key={user?.id} className='flex justify-between items-center'>
              <PendingInvite email={email} isAnonymousUser={isAnonymousUser} />
              <CollaboratorItem
                {...{
                  user,
                  role,
                  handleRoleChange,
                  isAnonymousUser,
                  currentUserId,
                  currentUserRole,
                  id,
                  collaborators,
                  roleOptionMap,
                  teams,
                }}
              />
            </div>
          );
        })}
        {teamShares.map((teamShare) => {
          return (
            <div key={teamShare.id} className='flex justify-between items-center effra-14 group'>
              <Team team={teamShare.team as TeamType} />
              {/* Only allow owner to remove team shares */}
              {currentUserIsOwner && (
                <div className='px-2 opacity-0 transition-opacity group-hover:opacity-100 w-[104px]'>
                  <button
                    type='button'
                    className='flex gap-2 items-center effra-12'
                    onClick={() => setTeamShareToRemove(teamShare)}
                  >
                    <div className='w-[16px] h-[16px]'>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
