import { CollapsibleSection, useEditContext } from '@/components/cards';
import { ModalContext } from '@/components/modals';
import AddCustomFieldModal from '@/components/modals/AddCustomFieldModal/AddCustomFieldModal';
import DeleteCustomFieldConfirmation from '@/components/modals/DeleteCustomFieldConfirmation/DeleteCustomFieldConfirmation';
import ModuleWrapper from '@/components/modules/utils/ModuleWrapper';
import { type Module } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { getOrderedModuleEntries } from '../ModularObject/Page/utils';

import type { DiffInput } from '@/__generated__/types';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { GetModularObjectHistoryByIdDocument } from '@/components/cards/UpdatesColumn/getModularObjectDiffs.generated';
import { useUpdateAttributeMutation } from '@/components/modules/UpdateAttribute.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';

export default function CustomFields () {
  const { canUserEdit } = useEditContext();
  const { getValues, setValue } = useFormContext();
  const [modules]: Array<Record<string, Module>> = getValues(['customFields', 'data']) || [];
  const orderedModuleEntries = getOrderedModuleEntries(modules);

  const { showModal, closeModal } = useContext(ModalContext);

  const [updateAttribute] = useUpdateAttributeMutation();
  const { objectCardData } = useObjectCardContext();

  const handleSetValue = (update) => {
    setValue(
      'customFields',
      { ...modules, ...update },
      { shouldDirty: true },
    );
  };

  const buildUpdateObjectDiffPayloadForDeleteCustomAttribute = useCallback(
    ({ objectId, fieldNameToDelete }: { objectId: string; fieldNameToDelete: string }) => {
      // Get the modules without the custom field to delete
      const { [fieldNameToDelete]: _, ...updatedModules } = modules;

      // Form the diff input payload
      const payload: DiffInput = {
        externalID: objectId,
        externalType: 'modular_object',
        diff: {
          to: { customFields: { ...updatedModules } },
          displayNames: { customFields: 'customFields' },
        },
        fromMigration: false,
        diffType: 'updated',
      };

      return payload;
    },
    [modules],
  );

  const handleDeleteCustomField = useCallback(
    async (fieldNameToDelete: string) => {
      // Build the update payload
      const updatePayload = buildUpdateObjectDiffPayloadForDeleteCustomAttribute({
        objectId: objectCardData?.id,
        fieldNameToDelete,
      });

      await updateAttribute({
        variables: {
          input: {
            diffs: [updatePayload],
          },
        },
        refetchQueries: [GetModularObjectHistoryByIdDocument, GetHistoryUpdatesForObjectIdDocument],
        onCompleted: (data) => {
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
          const isSaved = !fieldsPendingApprovalMap[fieldNameToDelete];

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully deleted custom field'
            : 'Successfully requested approval for custom field deletion';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: () => addToastError('Failed to delete custom field, please try again'),
      });
    },
    [buildUpdateObjectDiffPayloadForDeleteCustomAttribute, objectCardData?.id, updateAttribute],
  );

  return (
    <>
      <CollapsibleSection
        title='Custom Fields'
        noPadding
        className='pb-2 pt-8 px-[24px]'
        lightHeader
      >
        {orderedModuleEntries.length > 0 ? (<div className='flex flex-col gap-4 pt-8'>
          {
            orderedModuleEntries.map(([key, module], idx) => {
              return (
                <div key={`data.${key}`}>
                  <div className='relative w-1/2 group'>
                    {canUserEdit &&
                      (
                        <FontAwesomeIcon
                          icon={faTrash}
                          size='lg'
                          className={cx(
                            'text-gray-ghost opacity-0 group-hover:opacity-100 transition-all absolute cursor-pointer -right-6 top-9',
                            {
                              '!-right-6': module?.properties?.suffix,
                            },
                          )}
                          onClick={() => {
                            showModal(
                              <DeleteCustomFieldConfirmation
                                closeModal={closeModal}
                                handleDeleteCustomField={() => {
                                  handleDeleteCustomField(key);
                                }}
                              />,
                            );
                          }}
                        />
                      )}
                    <ModuleWrapper module={module} index={idx} path={`data.${key}`} />
                  </div>
                </div>
              );
            })}
        </div>) : <div/>
        }
        {canUserEdit &&
          (
            <button
              onClick={e => {
                e.preventDefault();
                showModal(
                  <AddCustomFieldModal
                    closeModal={closeModal}
                    setValue={handleSetValue}
                    existingModules={modules}
                  />,
                );
              }}
                className={cx('font-medium', 'w-[140px]', 'h-[32px]', 'btn-primary-hollow', 'effra-xs', 'rounded-[2px]')}
              >
              Add Custom Field
            </button>
          )}
      </CollapsibleSection>
    </>
  );
}
